import { Badge } from '@chakra-ui/react';
import { EndpointStatus, EndpointStatusProps } from '../models';

function StatusBadge({ status }: EndpointStatusProps) {
  const statusConfig: Record<EndpointStatus, { color: string; text: string }> = {
    [EndpointStatus.HEALTHY]: { color: 'green', text: 'Healthy ↗' },
    [EndpointStatus.UNREACHABLE]: { color: 'red', text: 'Unreachable ↗' },
    [EndpointStatus.UNHEALTHY]: { color: 'yellow', text: 'Unhealthy ↗' },
    [EndpointStatus.ERROR]: { color: 'red', text: 'Error ↗' },
  };

  const { color, text } = statusConfig[status];

  return <Badge colorScheme={color}>{text}</Badge>;
}
export default StatusBadge;
