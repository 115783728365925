import { Spinner, HStack, useDisclosure, VStack } from '@chakra-ui/react';
import { useState } from 'react';
import { AttestorReportDataMap, AttestorReport, AttestorEnvironment } from '../models';
import DetailsModal from './DetailsModal';
import AttestorStatusTable from './AttestorStatusTable';
import StatusSummary from './StatusSummary';

function Status(props: { data: AttestorReportDataMap; isLoading: boolean }) {
  const [selectedEndpoint, setSelectedEndpoint] = useState<
    [AttestorReport, AttestorEnvironment] | null
  >(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, isLoading } = props;

  const openDetails = (item: AttestorReport, type: AttestorEnvironment) => {
    setSelectedEndpoint([item, type]);
    onOpen();
  };

  if (isLoading) {
    return <Spinner size="xl" />;
  }

  const statusSummaryItems = Object.entries(data).map(([key, value]) => (
    <StatusSummary key={key} title={key as AttestorEnvironment} data={value} />
  ));

  const attestorStatusTableItems = Object.entries(data).map(([key, value]) => (
    <AttestorStatusTable
      key={key}
      title={key as AttestorEnvironment}
      data={value}
      openModal={openDetails}
    />
  ));

  return (
    <VStack w={'80%'} spacing={'50px'}>
      <HStack w={'100%'} justifyContent={'center'} spacing={'30px'}>
        {statusSummaryItems}
      </HStack>
      {attestorStatusTableItems}
      {selectedEndpoint && (
        <DetailsModal
          type={selectedEndpoint[1]}
          selectedEndpoint={selectedEndpoint[0]}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
    </VStack>
  );
}

export default Status;
