import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const theme = extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: true,
  },
  components: {
    Td: {
      baseStyle: (props: any) => ({
        color: mode('blue.400', 'whiteAlpha.900')(props),
      }),
    },
  },
});

export default theme;
