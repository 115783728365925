import { EndpointStatus } from "models";

export function formatUUID(uuid: string): string {
  return `${uuid.slice(0, 4)}...${uuid.slice(-4)}`;
}

export function analyzeItemStatus(data: any[], statusField: string = 'status') {
  if (!Array.isArray(data)) {
    return {
      total: 0,
      healthy: 0,
      unhealthy: 0,
      healthyPercentage: 0,
      unhealthyPercentage: 0,
    };
  }

  const total = data.length;
  const healthy = data.filter((item) => item[statusField] === EndpointStatus.HEALTHY).length;
  const unhealthy = total - healthy;

  const healthyPercentage = total > 0 ? Math.round((healthy / total) * 100) : 0;
  const unhealthyPercentage = total > 0 ? Math.round((unhealthy / total) * 100) : 0;

  return {
    total,
    healthy,
    unhealthy,
    healthyPercentage,
    unhealthyPercentage,
  };
}

export function formatToFourDecimals(value: number): number {
  return parseFloat(value.toFixed(4));
}


