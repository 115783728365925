import { Badge } from '@chakra-ui/react';

function BitcoinBadge({ status }: { status: boolean | undefined }) {
  if (status === undefined) return <Badge colorScheme="yellow">?</Badge>;
  const statusConfig = status ? { color: 'green', text: 'Yes' } : { color: 'red', text: 'No' };

  const { color, text } = statusConfig;

  return <Badge colorScheme={color}>{text}</Badge>;
}
export default BitcoinBadge;
